import { Injectable, InjectionToken, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Howl } from 'howler';
import { Sound, SoundConfig, SoundTypeEnum } from '../../shared/models/Sound';

export const SOUND_TOKEN = new InjectionToken<SoundConfig>('SoundConfig');

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private readonly soundData$: BehaviorSubject<SoundConfig>;

  constructor() {
    const soundConfig = inject<SoundConfig>(SOUND_TOKEN);
    this.soundData$ = new BehaviorSubject(soundConfig);
  }

  // Update config at runtime if needed
  loadSound(soundData: SoundConfig) {
    this.soundData$.next(soundData);
  }

  playSound(soundType: SoundTypeEnum) {
    const currentConfig = this.soundData$.value;
    if (!currentConfig) {
      console.error('No sound configuration found.');
      return;
    }

    // 1) Find the sound type
    const soundCategory = currentConfig.soundTypes.find(
      (st) => st.type === soundType,
    );
    if (!soundCategory) {
      console.error('Sound type not found:', soundType);
      return;
    }

    // 2) Pick a random sound in that category
    const randomSound: Sound =
      soundCategory.sounds[
        Math.floor(Math.random() * soundCategory.sounds.length)
      ];

    // 3) Play as loop or once
    if (randomSound.loop) {
      this.playLoop(randomSound);
    } else {
      this.playOnce(randomSound.filePath);
    }
  }

  /**
   * Play an audio file once using Howler.js
   */
  private playOnce(filePath: string, offsetSeconds: number = 0): void {
    const sound = new Howl({
      src: [filePath],
      html5: true, // ensures iOS device volume/mute switch is respected
    });

    // If offsetSeconds is specified, seek to that position before playing
    sound.once('load', () => {
      sound.seek(offsetSeconds);
      sound.play();
    });

    // Optionally, handle the end event
    sound.on('end', () => {
      // Sound completed
    });

    // Handle any errors
    sound.on('playerror', (soundId: number, err: unknown) => {
      console.error('Howler play error:', err);
    });
    sound.on('loaderror', (soundId: number, err: unknown) => {
      console.error('Howler load error:', err);
    });
  }

  /**
   * Partial loop logic using Howler.
   * 1) Plays from 0 → loopStart (if loopStart > 0).
   * 2) Loops from loopStart → loopEnd for the specified loopContinue duration.
   * 3) Finally plays from loopEnd → end (one time).
   */
  private playLoop(sound: Sound) {
    const { filePath, loopStart = 0, loopEnd = 0, loopContinue = 0 } = sound;

    // If no partial loop, just do a full loop
    if (!loopStart && !loopEnd) {
      const howl = new Howl({
        src: [filePath],
        html5: true,
        loop: true, // Loop entire audio
      });
      howl.play();
      return;
    }

    // Convert milliseconds to seconds for Howler
    const loopStartSec = loopStart / 1000;
    const loopEndSec = loopEnd / 1000;
    const loopDurationMs = (loopEndSec - loopStartSec) * 1000;
    const loopCount =
      loopDurationMs > 0 && loopContinue > 0
        ? Math.ceil(loopContinue / loopDurationMs)
        : 1;

    // 1) Play from 0 → loopStart
    if (loopStartSec > 0) {
      this.playSegment(filePath, 0, loopStartSec)
        .then(() => {
          // 2) Now play loop portion multiple times
          return this.playLoopSegment(
            filePath,
            loopStartSec,
            loopEndSec,
            loopCount,
          );
        })
        .then(() => {
          // 3) Finally play from loopEnd → track end
          this.playOnce(filePath, loopEndSec);
        })
        .catch((err) => {
          console.error('Error in partial loop chain:', err);
        });
    } else {
      // Start directly at the loop segment
      this.playLoopSegment(filePath, loopStartSec, loopEndSec, loopCount)
        .then(() => {
          this.playOnce(filePath, loopEndSec);
        })
        .catch((err) => {
          console.error('Error in partial loop chain:', err);
        });
    }
  }

  /**
   * Play segment from `startSec` → `endSec` once. Returns a Promise that resolves when segment ends.
   */
  private playSegment(
    filePath: string,
    startSec: number,
    endSec: number,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (endSec <= startSec) {
        // Edge case: invalid times, just resolve immediately
        resolve();
        return;
      }

      const howl = new Howl({
        src: [filePath],
        html5: true,
      });

      // When loaded, seek to startSec and play
      howl.once('load', () => {
        howl.seek(startSec);
        const duration = howl.duration(); // total track length in seconds

        // If endSec extends beyond total track length, clamp it
        const actualEndSec = Math.min(endSec, duration);

        // Check playback time periodically to see if we've crossed endSec
        const intervalId = setInterval(() => {
          const currentPos = howl.seek() as number;
          if (currentPos >= actualEndSec) {
            clearInterval(intervalId);
            howl.stop();
            howl.unload(); // free resources
            resolve();
          }
        }, 50);

        howl.play();
      });

      howl.on('playerror', (id: any, err: any) =>
        reject(`playSegment error: ${err}`),
      );
      howl.on('loaderror', (id: any, err: any) =>
        reject(`loadSegment error: ${err}`),
      );
    });
  }

  /**
   * Repeatedly plays the segment from loopStartSec → loopEndSec `loopCount` times.
   */
  private playLoopSegment(
    filePath: string,
    loopStartSec: number,
    loopEndSec: number,
    loopCount: number,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let currentLoop = 0;

      // We'll create a single Howl to reuse for each loop iteration
      const loopHowl = new Howl({
        src: [filePath],
        html5: true,
      });

      // Once it's loaded, start the first loop
      loopHowl.once('load', () => {
        // Each iteration: jump to loopStartSec and play until loopEndSec
        const playLoopIteration = () => {
          loopHowl.seek(loopStartSec);
          loopHowl.play();

          // Poll current time
          const intervalId = setInterval(() => {
            const currentPos = loopHowl.seek() as number;
            if (currentPos >= loopEndSec) {
              clearInterval(intervalId);
              loopHowl.pause(); // or stop, but pause is typically smoother
              currentLoop++;
              if (currentLoop < loopCount) {
                playLoopIteration(); // start next iteration
              } else {
                // Done looping
                loopHowl.unload();
                resolve();
              }
            }
          }, 50);
        };

        // Kick off the first iteration
        playLoopIteration();
      });
    });
  }
}
